import React from 'react';
import classes from './Overlay.module.scss';
import clsx from 'clsx';
import CrossIcon from '../../assets/CrossIcon';

export interface Props {
  onClose: () => void;
  showClose?: boolean;
  className?: string;
  backgroundTestId?: string;
  contentTestId?: string;
  children: React.ReactNode;
}

const Overlay: React.FC<Props> = ({
  onClose,
  showClose,
  children,
  className,
  backgroundTestId,
  contentTestId,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const handleClickOutside = React.useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as HTMLButtonElement)) {
        onClose();
      }
    },
    [onClose],
  );

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref, handleClickOutside]);

  return (
    <div className={clsx(classes.root, className)} data-testid={backgroundTestId}>
      <div className={classes.contentOuterWrapper}>
        <div className={classes.contentInnerWrapper} ref={ref}>
          <div className={classes.content} ref={ref}>
            {showClose && (
              <button
                type="button"
                onClick={onClose}
                className={classes.close}
                data-testid={contentTestId}
              >
                <CrossIcon />
              </button>
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overlay;
